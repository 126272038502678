import { PublicTheme } from '../../common/theme/PublicTheme'
import { HomeSectionJ } from '../home/components/homeSectionJ'
import header from './../../common/assets/images/fotos-portada.png'
import { LegalsBYP } from '../about/components/legalsBYP'
//detectar el tipo de usuario y mostrar los legales según su perfil
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'

const URL_ABOUT_BYP_LEGALS = '/about/BYPLegalTerms'

function BYPLegalTerms () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <PublicTheme>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'strech',
          alignItems: 'center',
          padding: 20,
          marginTop: 3,
          marginBottom: 40}}>
          <img src={header} style={{ width: '100%', height: '15rem', objectFit: 'cover', borderRadius: '.6rem', marginBottom: 20 }} alt='' />
          <br />
          <LegalsBYP />          
        </div>
        <HomeSectionJ />
      </PublicTheme>
    </PageProtected>
  )
}

export { BYPLegalTerms, URL_ABOUT_BYP_LEGALS }
