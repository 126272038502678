import React, { useState } from "react";
import { Collapse } from 'antd';
import { Link } from "react-router-dom";

function LegalsBYP({ mailto, label }){

    const titles = {
       fontSize: '1.6rem',
       marginBottom: '1rem',
       marginTop: '1rem',
   };
    
    return(
        <>
         <Collapse 
         style={{ 
            width: '90%',
            fontSize: 14}}>
            {/* Términos de Servicio */}
            <h2 style={titles}>Términos del servicio</h2>
            <Collapse.Panel header="Términos de Servicio Bookyourprogram.com">
                <small>Última actualización: 03 Enero 2025 </small>
                <p>
                    <b>¡Gracias por utilizar Bookyourprogram!</b>
                </p>
                <p>
                    Los Presentes Términos de Servicio <b>(“Términos”)</b> son un contrato legal vinculante entre usted y Bookyourprogram.com  que rigen el derecho a usar los sitios web, las aplicaciones y otros ofrecimientos de  Bookyourprogram.com (colectivamente, la <b>“Plataforma Bookyourprogram.com”</b>). Cuando estos Términos utilizan <b>“Bookyourprogram.com,” “nosotros,” “nos” o “nuestros”</b> se refieren a la entidad de Bookyourprogram.com mencionada con quien usted contrata.
                </p>
                <p>
                    La Plataforma Bookyourprogram.com  permite a los usuarios <b>(“Miembros”)</b> explorar cotizar y gestionar su inscripción de estudios al extranjero a través de la plataforma. Los miembros son los clientes, estudiantes, familiares de los estudiantes o personas involucradas en el programa de estudios en el extranjero. <b>“Las Instituciones”</b>  son las escuelas de idiomas, universidades, colleges, escuelas secundarias y preparatorias,  empresas u organizaciones internacionales que coordinan programas de estudios al extranjero como son campamentos de verano, au pair, voluntariados. 
                    <b>“Empresas de servicios educativos”</b>  son aquellas empresas que complementan el servicio de estudios al extranjero como son empresas de financiamiento educativo, gestores de visados, empresas de trraducciones entre otros.  
                </p>
                <p>
                    Como proveedor de la Plataforma Bookyourprogram.com, Bookyourprogram (o sus filiales) es un intermediario entre las instituciones, empresas de servicios educativos y los miembros. Bookyourprogram.com funge como una plataforma de información y consultoría educativa al extranjero y  no es parte de los contratos celebrados directamente entre los miembros y las instituciones y/o empresas de servicio educativo,  Bookyourprogram.com no es una agencia educativa, agencia  de viajes ni aseguradora. Bookyourprogram.com no actúa como agente de ningún miembro, excepto según lo especificado en los Términos del Servicio de Pagos
                    (“<b>Términos de Pago”</b>). 
                </p>
                <p>
                    Mantenemos otros términos y políticas que complementan estos Términos, como la Política de Privacidad, que describe la recopilación y el uso de datos personales, y los Términos de Pago, que rigen los servicios de pago prestados a los Miembros por las entidades de pago de Bookyourprogram.com.
                </p>
            </Collapse.Panel>

            <h2 style={titles}>Términos del servicio de pagos</h2>
            {/* Términos de Pago, liquidaciones, planes a pagos, reembolsos y facturas. */}
            <Collapse.Panel header="Términos de Pago, liquidaciones, planes a pagos, reembolsos y facturas">
                <small>Última actualización: 03 Enero 2025 </small>
                <p>
                Todos los pagos de los programas son en divisa local al tipo de cambio a la venta determinado por el banco o empresa de servicio de envío de pagos internacionales.  Este tipo de cambio será señalado al momento de liquidar o hacer un abono al programa contratado. El pago inicial de apartado es de 1,000 USD/Euros/CAN/Libras o la divisa del programa que se quiere contratar y se pagarán en moneda nacional al tipo de cambio a la venta del día que se realice el pago. Con el pago inicial se pagará costos de inscripción de la escuela y la cuota de servicio que  es de 250 USD/Euros/CAD/Libras o divisa del programa que se contrata. 
                </p>
                <p>
                    El pago inicial se puede hacer de la siguiente manera: 
                    <ul style={{fontSize: 14}}>
                        <li>
                            <b>Depósito de transferencia bancaria. A través del siguiente número de cuenta. <b>En esta opción NO se recibe dinero en efectivo.</b></b>
                            <p><b>Banco:</b>BBVA Bancomer</p>
                            <p><b>A nombre de:</b>Programas Interculturales de México SC.</p>
                            <p><b>Número de cuenta:</b>0113458164</p>
                            <p><b>Clabe Interbancaria:</b>012320001134581641</p>
                        </li>
                        <li>
                            <p>
                                <b>Depósito en efectivo:</b> En caso de querer hacer un pago en efectivo en ventanilla bancaria favor de solicitar la factura de Cohort a través del siguiente correo: 
                                <a href={`mailto:const email : nash.uriza@bookyourprogram.com;`}>{<span style={{color: 'blue'}}>nash.uriza@bookyourprogram.com</span>}</a>
                            </p>
                        </li>
                        <li>
                            <p><b>Pago con tarjeta de crédito bancaria:</b> Se puede realizar el pago con tarjeta de crédito bancaria  para pagos iniciales,  para abonos y liquidaciones hay que considerar  que puede haber costos adicionales de cargo por tarjeta. </p>
                        </li>
                        <li>
                            <p><b>Pago directo a la institución educativa</b> mediante transferencia bancaria o pagos a través de sus opciones de pagos. </p>
                        </li>
                    </ul>
                </p>
                <p>
                    Dentro de nuestras políticas y términos de pago. Al momento de hacer un pago usted está de acuerdo en los siguientes estatutos: 
                </p>
                <p>El cliente bajo protesta de decir verdad manifiesta que los recursos monetarios con los cuáles está contratando los servicios de bookyourprogram.com tienen su procedencia de actividades licitas y se sujeta a lo que establece dentro de la legislación Federal para la Prevención e identificación de operaciones con recursos de procedencia ilícita respecto de las actividades vulnerables a las que en dicha legislación se hace mención.</p>
                <p>
                El pago inicial no es reembolsable en caso de cancelación bajo ninguna circunstancia. Todas las inscripciones deberán de realizarse al menos con 4 semanas de anticipación, Inscripciones con menos tiempo de anticipación estarán sujetas a disponibilidad de lugares. 
                </p>
                <p><b>Liquidación del programa: </b></p>
                <p>Todos los programas deberán de estar liquidados al menos con 4 semanas de anticipación, Los pagos deberán de realizarse a través de las modalidades antes señaladas, y se pagarán en moneda nacional al tipo de cambio a la venta del día que se haga el depósito. </p>
                <p>
                Al momento de liquidar el programa se descontarán abonos o saldos a favor de pagos iniciales  realizados al inscribirse al programa, En caso de liquidar  por  semestres, el pago inicial  se abonará en el  último pago de colegiatura pendiente para liquidar el programa.
                </p>
                <p>
                En caso de ser necesario tramitar un visado de estudios el pago del programa deberá de estar cubierto al menos con 12 semanas de anticipación. 
                </p>
                <p>Pagos no realizados en los periodos anteriores señalados bookyourprogram.com tendrá la facultad de cancelar el programa de estudios o mover la fecha de inicio del programa. </p>
                <p>En caso de liquidar el programa directamente a la institución educativa se deberá de notificar a bookyourprogram.com para a su vez confirmar con ésta la liquidación del curso. </p>
                <p><b>Planes a pagos:</b></p>
                <p>
                En caso de estar bajo un plan a pagos, la mensualidad será cubierta al tipo de cambio a la venta del día que se cubra dicha mensualidad y ésta puede ser pagada de acuerdo a las modalidades arriba mencionadas con excepción de pago directo a la institución educativa. 
                </p>
                <p><b>Recibos de pago y facturación de los programas de estudios: </b></p>
                <p>Bookyourprogram.com entregará recibos de pagos por los conceptos o servicios que el cliente haya realizado. La factura será la que envíe la institución educativa contratada, bajo el nombre del estudiante que cursará el programa. </p>
                <p><b>Reembolsos:</b></p>
                <p>En caso de aplicar a cualquier reembolso es necesario enviar la siguiente documentación al correo de <a href={`mailto:const email : nash.uriza@bookyourprogram.com;`}>{<span style={{color: 'blue'}}>nash.uriza@bookyourprogram.com</span>}</a> en el correo deberá de contar con la siguiente información: Nombre del beneficiario,  número de cuenta,  CLABE Interbancaria, banco y motivo del reembolso.</p>
                <p>Todos los reembolsos tienen una duración promedio de 40 días hábiles, contados a partir de la fecha de enviar toda la documentación.  Los reembolsos serán en moneda local al tipo de cambio a la compra del día que se haga la transferencia.</p>
                <p>Los depósitos de garantía en caso de aplicar un reembolso se realizarán a partir de la tercera semana del término del programa de estudios contratado y/o cuando la institución educativa extranjera los envíe  y serán sujetos a revisión de no dejar adeudos en el programa. </p>
            </Collapse.Panel>

            <h2 style={titles}>Política de privacidad</h2>
            {/* Política de privacidad */}
            <Collapse.Panel header="Política de privacidad">
                <small>Última actualización: 04 de Enero 2025 </small>
                <p><b>Nuestra Política de Privacidad explica qué datos personales recopilamos, cómo se utilizan, cómo se comparten, y los derechos de privacidad.</b></p>
                <p><b>Bookyourprogram.com</b> existe para ayudar a conectar a las personas con las oportunidades de estudiar en el extranjero  y así construir un mundo más preparado, abierto e inclusivo.  En resumen construir una sociedad más preparada a través de los viajes de estudios al extranjero  que comprendan, empaticen y se sumerjan a otras culturas  y perspectivas. Nuestra comunidad se basa en la confianza, por lo que a través de este documento compartimos cómo usamos sus datos y cómo protegemos su derechos humanos a la privacidad.  Estas políticas de privacidad describen como International Education Consulting LLC  a través de sus empresas y marcas afiliadas  (“nosotros, “nuestro (s), nuestra(s), nos” o bookyourprogram.com) tratan la información personal en relación al uso que usted hace en la plataforma de Bookyourprogram.com y marcas afiliadas.</p>
                <p>Dependiendo de donde viva y de lo que esté haciendo en la Plataforma de Bookyourprogram.com las páginas de privacidad complementarias que se enumeran a continuación pueden resultarles aplicables.  Lo invitamos a que siga los enlaces y revise la información  complementaria para conocer cómo tratamos los datos e información personal para esas regiones y servicios.</p>
                <p><b>Datos personales que recabamos:</b></p>
                <p><b>Información necesaria para usar la plataforma de Bookyourprogram.com y marcas afiliadas.</b> Recabamos información personal sobre usted cuando utiliza la plataforma de Bookyourprogram.com, Sin ella, es posible que no podamos proporcionar todos los servicios solicitados.  Esta información incluye lo siguiente:</p>
                <p>
                    1.0 Información de contacto, cuenta, perfil,  Por ejemplo su nombre completo, número telefónico, dirección postal, dirección de correo electrónico, fecha de nacimiento y  foto de perfil;  algunos de los cuáles dependerán de las características que utilice.
                </p>
                <p>
                    1.1 Información adicional del perfil como género, idioma de preferencia, ciudad y descripción personal. 
                </p>
                <p>
                    1.11 Información de identidad: Cuando corresponda, podemos pedirle una imagen de su identificación oficial (de acuerdo con las leyes aplicables), u otra información adicional de identificación como una selfie, datos de pasaporte, pasaporte de padres  (en caso de menores de edad)  para poder continuar con el proceso de aplicación en menores de edad.
                </p>
                <p>
                    1.12 Información de historial académico: Cuando corresponda podemos pedirle documentos de historial académico como son calificaciones y certificados de estudios, cartas de recomendación. títulos universitarios  para poder cumplir con los requisitos que solicitan las instituciones y programas contratados. 
                </p>
                <p>
                    1.13 Información de historial médico:  Cuando corresponda podemos solicitarle documentos de historial médico como son cartilla de vacunación, historial médico sobre alergias, situaciones médicas que deberá el programa o institución contratada estar enterado para asegurar el bienestar del participante 
                </p>
                <p>
                    1.14 Información sobre otros: como un requisito para el proceso de inscripción en algunos casos es necesario proporcionar contactos de emergencia, contactos de padres o tutores. Al proporcionarnos datos personales sobre terceros, usted certifica que tiene permiso  para proporcionar esa información a Bookyourprogram.com para fines descritos en esta Política de privacidad  y que compartió la Política de Privacidad de Bookyourprogram.com con ellos.
                </p>
                <p>
                    1.15 Información Biométrica. Como los datos de reconocimiento facial derivados de fotos y documentos de identificación que envíe para su verificación, cuando se ofrezcan y con su consentimiento cuando lo exija la ley aplicable.
                </p>
                <p>
                    1.16 Otra Información. Por ejemplo, cuando llene un formulario, agregue información a su cuenta, responda a encuestas, publique en foros de la comunidad, participe en promociones, se comunique con nuestro Servicio de Atención al Cliente y con otros miembros, facilite su dirección y/o geolocalización, o comparta su experiencia con nosotros. Esto puede incluir información de salud, si decide compartirla con nosotros.
                </p>
                <p>
                1.17. Información de Pago. En caso de realizar pago de una inscripción o liquidación de un programa de estudios al extranjero  a través de la plataforma. Se podría recolectar información de su tarjeta de crédito para proceder a pagos.
                </p>
                <p>
                    1.18 Información recabada automáticamente mediante la plataforma de Bookyourprogram.com y nuestro servicio de pago. Cuando utiliza la plataforma de Bookyourprogram.com y los servicios de pago, recabamos cierta información automáticamente esta información puede incluir:
                </p>
                <p>
                    1.19 Información de Geolocalización, como la ubicación precisa o aproximada, determinada a partir de la dirección IP, el GPS de su celular u otro dispositivo u otra información que comparta con nosotros,dependiendo de su configuración de su dispositivo.
                </p>
                <p>
                    1.20 Información de Uso. Como las búsquedas de Anuncios, las reservaciones realizadas, los servicios adicionales agregados, las fechas y horas de acceso, las páginas que vio o con las que interactúe antes o después de usar la Plataforma de Bookyourprogram.com y otras acciones realizadas en la Plataforma Bookyourprogram.com, incluidas las páginas o el contenido que ve y los enlaces en los que hace clic a aplicaciones de terceros. Es posible que recabemos esta información aunque no haya creado una cuenta en Bookyourprogram.com o iniciado sesión.
                </p>
                <p>
                    1.21  Información del Dispositivo. Como la dirección IP, la información de hardware y software, la información del dispositivo, la información de los eventos del dispositivo, los identificadores únicos, los datos de error y los recibos de lectura. Es posible que recabemos esta información aunque no haya creado una cuenta en Bookyourprogram.com o iniciado sesión.
                </p>
                 {/* Meter click a Politica de Cookies */}
                <p>
                    1.22  Cookies y Tecnologías Similares, según se describen en nuestra Política de Cookies.
                </p>
                <p>
                1.23 Información de la Transacción de Pago. Tal como el instrumento de pago utilizado, la fecha y la hora, el monto del pago, la fecha de vencimiento del instrumento de pago y el código postal de facturación, la dirección de correo electrónico de PayPal, la información de IBAN, su dirección y otros detalles relacionados con la transacción.
                </p>
                <p>
                    1.24 Información que Recabamos de Terceros. Es posible que recabemos información personal de otras fuentes, como
                </p>
                <p>
                    1.25 Aplicaciones de Terceros. Si elige vincular, conectarse o iniciar sesión en la Plataforma Airbnb con un servicio de terceros, como Google, Facebook y WeChat, usted ordena a dicho servicio que nos envíe información, como su registro, su lista de amigos y la información de su perfil, según la controle dicho servicio o como usted la autorice a través de la configuración de privacidad de dicho servicio. Si decide conectar una cerradura inteligente a su cuenta de Airbnb, es posible que recabemos información sobre el dispositivo inteligente, como la información de registro o de eventos y la información del dispositivo.
                </p>
                <p>
                    1.26  Referencias y Compañeros de Viaje. Si lo invitan a la Plataforma de Bookyourprogram.com, por ejemplo, para conocer la oferta académica que ofrecemos, la persona que lo invitó puede enviar información personal sobre usted.
                </p>
                <p>
                    1.27 Planes de Pago Flexibles y Proveedores de Financiamiento. Si elige comprar una reservación y pagar en un plan de pago a plazos, es posible que recibamos cierta información, como el calendario de pagos y los pagos reales en los planes de pago autorizados.
                </p>
                <p>
                    1.28 Otras Fuentes. En la medida en que lo permita la legislación aplicable, es posible que recibamos información adicional sobre usted, como <b>referencias</b>, datos demográficos e información para ayudar a detectar el fraude y los problemas de seguridad de (i) terceros proveedores de servicios, otros terceros o socios, o (ii) Miembros y cualquier otra persona, entidad o autoridad, y la combinemos con la información que tengamos sobre usted. Por ejemplo, podemos recibir resultados de verificaciones de antecedentes penales o advertencias de fraude por parte de proveedores de servicios de verificación de identidad para utilizarlos en nuestros procesos de prevención de fraudes, investigaciones de seguridad y evaluación de riesgos. Es posible que recibamos información sobre usted y sus actividades dentro y fuera de la Plataforma Bookyourprogram.com, incluso de usuarios de Booyourprogram.com, miembros del público o autoridades gubernamentales, públicas o fiscales. Podemos recibir información de salud, que incluye, pero no se limita a, información de salud relacionada con enfermedades contagiosas.
                </p>
                <p>
                1.29  Uso de fotos y videos: Es posible que Bookyourprogram.com colecte fotos de su experiencia en el extranjero, ya sea por compartirlas en nuestra comunidad de experiencias o recolectadas por la institución educativa donde usted se inscribió para estudiar en el extranjero.  Estas fotos y videos son para uso exclusivo de promoción  de éstos. Usted tiene derecho a  no autorizar el uso de estas fotos y videos para uso de promoción de los programas en el extranjero enviando una solicitud al correo electrónico proporcionado en nuestro sitio web.
                </p>
                <p>
                1.30  Contacto Si tiene alguna pregunta o inquietud sobre nuestro aviso de privacidad, puede comunicarse con nosotros a través del correo electrónico que aparece en nuestro sitio.
                </p>
                <p><b>2. CÓMO UTILIZAMOS LA INFORMACIÓN RECABADA.</b> Usamos los datos e información personal como se describe en esta Política de Privacidad.</p>
                <p><b>2.1 Proporcionar, Mejorar y Desarrollar la Plataforma Bookyourprogram.com.</b> Podemos tratar esta información para:</p>
                <ul>
                    <li>permitirle acceder a la Plataforma Bookyourprogram.com y realizar y recibir pagos,</li>
                    <li>permitir que se comunique con los demás,</li>
                    <li>procesar sus solicitudes,</li>
                    <li>realizar análisis, depurar y realizar investigaciones,</li>
                    <li>desarrollar y mejorar nuestros productos y servicios,</li>
                    <li>proporcionar capacitaciones de servicio al cliente,</li>
                    <li>enviarle mensajes, actualizaciones, alertas de seguridad y notificaciones de cuentas,</li>
                    <li>procesar, gestionar o evaluar reclamaciones de seguros o similares,</li>
                    <li>determinar su país de residencia en función de la revisión automatizada de la información de su cuenta y sus interacciones con la Plataforma Bookyourprogram.com,</li>
                    <li>configurar y personalizar su experiencia en función de sus interacciones con la Plataforma Bookyourprogram.com, su historial de búsqueda y reservaciones, sus preferencias e información de perfil y otros contenidos que envíe, y</li>
                    <li>habilitar el uso de nuestros productos y servicios de alojamiento.</li>
                </ul>
                <p><b>2.2 Proteger la Plataforma Bookyourprogram.com y a la Comunidad.</b> Podemos tratar esta información para:</p>
                <ul>
                     {/* Meter click a Politica de Discriminacion */}
                    <li>estudiar y combatir la discriminación de conformidad con nuestra Política contra la Discriminación,</li>
                    <li>detectar, prevenir, evaluar y abordar el fraude y los riesgos de seguridad,</li>
                    <li>verificar o autenticar la información que usted proporcione, incluida la información de identidad, como se describe en Información necesaria para usar la Plataforma Bookyourprogram.com,</li>
                    <li>realizar verificaciones con bases de datos y otras fuentes de información, incluidas verificaciones de antecedentes,</li>
                    <li>cumplir con nuestras obligaciones legales, proteger la salud y el bienestar del cliente, comunidad, profesores,</li>
                    <li>resolver disputas con nuestros clientes e instituciones, incluyendo compartir información con sus hacer cumplir nuestros acuerdos con terceros,</li>
                    <li>cumplir con la ley, responder a las solicitudes legales, prevenir daños y proteger nuestros derechos (ver Cumplir con la Ley, Responder a las Solicitudes Legales, Prevenir Daños y Proteger Nuestros Derechos),</li>
                    {/* Meter click a Politica de Discriminacion */}
                    <li>hacer cumplir nuestros Términos y otras políticas (por ejemplo, la Política contra la Discriminación), y</li>
                    <li>evaluar sus interacciones con la Plataforma Bookyourprogram.com y la información obtenida de terceros.</li>
                </ul>
                <p>En ciertos casos, los procesos automatizados, que analizan su cuenta y actividades en la Plataforma Bookyourprogram.com, así como la información relacionada con actividades dentro y fuera de la Plataforma Bookyourprogram.com que puedan asociarse con usted, podrían restringir o suspender su acceso a la Plataforma Bookyourprogram.com si dichos procesos detectan actividades que puedan suponer un riesgo de seguridad u otro tipo para Airbnb, para nuestra comunidad o para terceros. Obtenga más información sobre cómo nuestro sistema determina si ciertas inscripciones pueden conllevar un riesgo alto para incidentes. Si quiere impugnar decisiones basadas en procesos automatizados, comuníquese con nosotros a través de la sección de Información de Contacto.</p>
                <p><b>2.3 Proporcionar, Personalizar, Medir y Mejorar Nuestra Publicidad y Mercadotecnia.</b> Podemos tratar estos datos para:</p>
                <ul>
                    <li>enviarle mensajes promocionales y de marketing, y otra información,</li>
                    <li>mostrar, personalizar, medir y mejorar nuestra publicidad en plataformas publicitarias,</li>
                    <li>administrar programas de recomendaciones, recompensas, encuestas, sorteos, concursos u otras actividades o eventos promocionales patrocinados o gestionados por Bookyourprogram.com o sus socios externos</li>
                    <li>analizar características y preferencias para enviarle mensajes promocionales, información de marketing, publicidad y otra que creamos que pueda ser de su interés e</li>
                    <li>invitarlo a eventos y oportunidades relevantes.</li>
                </ul>
                <p><b>2.4 Analizar y Compartir sus Comunicaciones.</b> Podríamos revisar, inspeccionar o analizar sus comunicaciones en la Plataforma Bookyourprogram.com  por los motivos descritos en la sección “Cómo Utilizamos la Información Recabada” de esta política, que incluye motivos de prevención de fraudes, investigaciones de seguridad, evaluación de riesgos, cumplimiento normativo, desarrollo de productos, estudios, análisis, cumplimiento de nuestros Términos de Servicio y atención al cliente. Por ejemplo, como parte de nuestros esfuerzos de prevención de fraudes, examinamos y analizamos los mensajes para ocultar la información de contacto y referencias a otros sitios web, y sujeto a la legislación aplicable, examinamos y analizamos todas la información que los usuarios suben a la Plataforma Bookyourprogram.com en hilos de mensajes, perfiles, Anuncios y Experiencias para detectar ciertas actividades ilegales o inapropiadas (como evidencia de explotación infantil) con el fin de identificar y denunciar infracciones de contenido a las autoridades competentes. En algunos casos, también inspeccionaremos, revisaremos o analizaremos los mensajes para depurar, mejorar y ampliar la oferta de productos. Utilizamos métodos automatizados cuando es razonablemente posible. Sin embargo, en ocasiones, es posible que tengamos que revisar manualmente algunas comunicaciones, como las investigaciones de fraude y el servicio de atención al cliente, o evaluar y mejorar la funcionalidad de estas herramientas automatizadas. No revisaremos, examinaremos ni analizaremos sus comunicaciones para enviarle mensajes de marketing de terceros, y no venderemos evaluaciones o análisis de estas comunicaciones. </p>
                <p><b>2.5 Prestar Servicios de Pago.</b> Los datos e información personal se utilizan para permitir o autorizar a terceros el uso de los Servicios de Pago, entre otros fines:</p>
                <ul>
                    <li>detectar y prevenir el lavado de dinero, el fraude, los abusos y los incidentes de seguridad, así como realizar evaluaciones de riesgos,</li>
                    <li>cumplir las obligaciones legales y de cumplimiento normativo (tales como las disposiciones contra el lavado de dinero y la aplicación de sanciones),</li>
                    <li>hacer cumplir los Términos de Pago y otras políticas de pago, y</li>
                    <li>proporcionar y mejorar los Servicios de Pago.</li>
                </ul>
                <p><b>3. INTERCAMBIO Y DIVULGACIÓN DE INFORMACIÓN. </b></p>
                <p><b>3.1 Compartir con su consentimiento o bajo su Dirección.</b> Cuando haya dado su consentimiento o nos indique que compartamos su información, compartimos su información, tal como se describe al momento de que proporciona su consentimiento o realiza la elección correspondiente, como cuando autorizamos a una aplicación o sitio web de terceros a acceder a su cuenta en Bookyourprogram.com, cuando apoyamos a procesar una reclamación de seguro, cuando solicitamos financiamiento flexibles o cuando participamos en actividades promocionales de socios de Bookyourprogram.com o de terceros.</p>
                <p><b>3.2 Con quién compartimos.</b> Podemos compartir su información con:</p>
                {/* Meter click a Compartir info */}
                <p><b>3.2.1 Otros Miembros.</b> Para ayudar a facilitar el proceso de aplicación a un programa al extranjero como empresas de servicios externos  (por ejemplo instituciones, traductores, empresas de apoyo en gestión de visados) u otras interacciones entre los Miembros (que pueden estar ubicados en jurisdicciones con distintos niveles de protección de datos o recurrir a proveedores de servicios ubicados en dichas jurisdicciones), es posible que tengamos que compartir información en determinadas situaciones, como se describe en Compartir y divulgar información con otros Miembros.</p>
                <p><b>3.2.2 Proveedores de Servicios para Miembros.</b> Las instituciones  o  empresas de servicios externos pueden utilizar servicios de terceros para administrar o prestar sus servicios.Otros Miembros pueden utilizar servicios distintos de Bookyourprogram.com para tratar sus datos, incluido un correo electrónico o un software de gestión de clientes. Dichos servicios están fuera del control de Bookyourprogram.com  y estarán sujetos a la ley aplicable.</p>
                <p><b>3.2.5 Proveedores de Servicios.</b> Compartimos información personal con proveedores de servicios afiliados y no afiliados (incluidos sus proveedores de servicios) 
                    para ayudarnos a administrar nuestro negocio y para sus propósitos de cumplimiento, incluidos aquellos que nos ayudan a: 
                    (i) verificar su identidad o autenticar sus documentos de identificación, 
                    (ii) cotejar información con bases de datos públicas, (iii)  llevar a cabo verificaciones de antecedentes penales, prevención de fraudes, investigaciones de seguridad y evaluación de riesgos, 
                    (iv) desarrollar, mantener y depurar productos, (v) permitir la prestación de los Servicios de Bookyourprogram.com a través de plataformas de terceros y herramientas de software,
                    (vi) proporcionar servicios de atención al cliente, publicidad o servicios de pago (consulte la <a href={`https://www.citibank.com/tts/sa/tts-privacy-statements/index.html`}>{<span style={{color: 'blue'}}>Política de Privacidad de Citi</span>}</a> y la <a href={`https://www.payoneer.com/legal/privacy-policy/`}>{<span style={{color: 'blue'}}>Política de Privacidad de Payoneer</span>}</a> para obtener más información), 
                    (vii) ofrecer servicios adicionales que usted seleccione,(viii) ofrecer planes de pago flexibles y financiación proporcionados por un proveedor de crédito externo. Consulte la sección Análisis e Intercambio de sus Comunicaciones
                    para obtener información adicional. Estos proveedores están obligados contractualmente a proteger su información personal, tienen acceso a su información personal para realizar estas tareas y también pueden divulgar su información cuando lo exija la ley.
                </p>
                <p><b>3.2.6 Afiliados Corporativos.</b> Para ayudarnos a brindar, integrar, promocionar y mejorar la Plataforma Airbnb, los Servicios de Pago y los servicios de nuestros afiliados, podríamos compartir información personal con nuestra familia corporativa de empresas</p>
                <p><b>3.2.6(iii) Afiliados del Agente de Seguros de Bookyourprogram.com.</b> Si decide contratar un seguro de viaje y servicios de asistencia de viaje  para proporcionar la póliza y comercializar las ofertas de seguros, cierta información, como los detalles de la inscripción,, el nombre del estudiante y la información de contacto de emergencia, se compartirá con la empresa externa de seguros de gastos médicos. </p>
                <p><b>3.3 Por Qué Podemos Compartir su Información.</b> Podemos compartir su información con el fin de:</p>
                <p><b>3.3.1 Construir su perfil público.</b>La información que comparta públicamente en la Plataforma Bookyourprogram.com podría indexarse a través de motores de búsqueda de terceros. En algunos casos, usted puede inhabilitar el intercambio de su información en la configuración de su cuenta. Podemos permitir que cierta información sea visible al público, como:
                    <ul>
                        <li>evaluaciones, calificaciones y otros comentarios públicos sobre su experiencia de estudios al extranjero, y</li>
                        <li>contenido en una comunidad o foro de discusión, blog o publicación en redes sociales, y contenido que usted ponga a disposición del público</li>
                    </ul>
                </p>
                <p><b>3.3.2 Cumplir con la Ley, Responder a las Solicitudes Legales, Prevenir Daños y Proteger Nuestros Derechos.</b></p>
                <p><b>3.3.2(i) Divulgación.</b> Podemos divulgar su información a los tribunales, a las autoridades policiales, gubernamentales o públicas, a las autoridades fiscales, a terceros autorizados o a otros Miembros, en la medida en que la ley nos lo exija o nos lo permita, o cuando la divulgación sea razonablemente necesaria para: (i) cumplir con nuestras obligaciones legales, (ii) cumplir con una solicitud legal válida, como una citación u orden judicial, o responder a las reclamaciones formuladas contra Bookyourprogram.com, (iii) responder a una solicitud legal válida relacionada con una investigación penal para abordar una actividad presuntamente ilegal, o responder o abordar cualquier otra actividad que pueda exponernos a nosotros, a usted o a cualquier otro de nuestros usuarios a una responsabilidad legal o normativa , (iv) hacer cumplir y administrar nuestros acuerdos con los Miembros, incluidos nuestros Términos,(v) responder a solicitudes de reclamaciones legales o procedimientos legales actuales o potenciales relacionados con Bookyourprogram.com  y/o terceros, de conformidad con la ley aplicable, o (vi) proteger los derechos, la propiedad o la seguridad personal de Bookyourprogram.com, sus empleados, sus Miembros o los miembros del público.</p>
                <p><b>3.3.3 Efectuar Transferencias Comerciales.</b> Si Bookyourprogram.com lleva a cabo o participa en cualquier fusión, adquisición, reorganización, venta de activos, quiebra o evento de insolvencia, podríamos vender, transferir o compartir parte o la totalidad de nuestros activos, incluida su información en relación con dicha transacción o con miras a tal transacción, como en una auditoría de compra. En tal caso, le enviaremos una notificación antes de que sus datos personales se transfieran y queden sujetos a una política de privacidad diferente.</p>
                <p><b>4. SOCIOS EXTERNOS E INTEGRACIONES.</b></p>
                <p><b>4.1 Vinculación de servicios de terceros.</b> Puede vincular su cuenta Bookyourprogram.com con determinados servicios de terceros, como las redes sociales. Al dirigir el intercambio de datos mediante la creación de este enlace:
                    <ul>
                        <li>parte de la información que nos proporcione sobre cuentas vinculadas puede publicarse en su perfil público,</li>
                        <li>la información que nos proporcione a partir de la vinculación de sus cuentas podría almacenarse, tratarse y transmitirse con fines de prevención de fraudes, investigaciones de seguridad y evaluación de riesgos,</li>
                        <li>La publicación y visualización de la información que proporcione a la Plataforma Bookyourprogram.com a través de esta vinculación está sujeta a los ajustes y autorizaciones que haya configurado tanto en la Plataforma Bookyourprogram.com como en el servicio externo.</li>
                    </ul>
                </p>
                <p><b>4.2 Condiciones de Servicio de Terceros.</b>
                 Algunas partes de Bookyourprogram.com pueden vincularse a servicios de terceros. 
                 Bookyourprogram.com no posee ni controla estos servicios de terceros. Cuando usted interactúa con estos terceros y 
                 decide utilizar sus servicios, les está proporcionando su información. El uso de estos servicios está sujeto a las políticas de privacidad 
                 de dichos proveedores, como los 
                 <a href={`https://www.google.com/intl/en_us/help/terms_maps.html`}>{<span style={{color: 'blue'}}>Términos de Uso Adicionales de Google Maps/Earth,</span>}</a>
                 <a href={`https://www.google.com/privacy.html`}>{<span style={{color: 'blue'}}>la Política de Privacidad de Google</span>}</a> (lea aquí para obtener más información sobre cómo Google utiliza la información), 
                 <a href={`https://www.paypal.com/us/webapps/mpp/ua/privacy-full`}>{<span style={{color: 'blue'}}>la Declaración de Privacidad de Paypal, </span>}</a>
                  y <a href={`https://stripe.com/privacy`}>{<span style={{color: 'blue'}}>la Política de Privacidad de Stripe.</span>}</a>
                </p>
                <p><b>5. SUS DERECHOS.</b> Puede ejercer cualquiera de los derechos descritos en esta sección de conformidad con la legislación aplicable. Consulte aquí para obtener información sobre solicitudes de derechos como usuario y cómo enviar una solicitud. Tenga en cuenta que quizá le pidamos que nos confirme su identidad antes de procesar la solicitud.</p>
                <p><b>5.3 Administración de su información.</b> Puede acceder a algunos de sus datos personales y actualizarlos a través de la configuración de su Cuenta. Usted es responsable de mantener actualizada su información personal.</p>
                <p><b>5.4 Acceso y Portabilidad.</b> En algunas regiones, la legislación aplicable puede darle el derecho a solicitar ciertas copias de sus datos personales o información sobre cómo tratamos sus datos personales, solicitar copias de los datos personales que nos haya proporcionado en un formato estructurado, de uso común y legible por máquina, y/o solicitar que transmitiremos esta información a otro proveedor de servicios, cuando sea técnicamente posible.</p>
                <p><b>5.5 Eliminación.</b> En ciertas jurisdicciones, usted puede solicitar que se elimine su información personal. Tenga en cuenta que si usted solicita la eliminación de sus datos personales o si su cuenta es suspendida, eliminada o borrada voluntariamente:</p>
                <p><b>5.5.1 Intereses Comerciales Legítimos.</b> Podemos conservar su información personal según sea necesario para nuestros intereses comerciales legítimos, tales como la prevención del lavado de dinero, la detección y prevención de fraudes y la mejora de la seguridad. Por ejemplo, si suspendemos una cuenta en Airbnb por fraude o por razones de seguridad, es posible que retengamos información de esa cuenta en Bookyourprogram.com para evitar que en el futuro ese miembro vuelva a abrir una nueva cuenta en Bookyourprogram.com.</p>
                <p><b>5.5.2 Obligaciones Legales, Fiscales, de Presentación de Informes y de Auditoría.</b> Podremos conservar y utilizar su información personal en la medida necesaria para cumplir nuestras obligaciones legales, fiscales, de presentación de informes y de auditoría.</p>
                <p><b>5.5.3 Información compartida.</b> La información que usted compartió con otras personas, como las evaluaciones y las publicaciones del foro, puede seguir visible públicamente en Bookyourprogram.com, incluso después de que cancele su cuenta en Bookyourprogram.com.</p>
                <p><b>5.5.4 Copias Residuales.</b> Debido a que tomamos medidas para proteger los datos de pérdidas y destrucción accidentales o maliciosas, es posible que las copias residuales de su información no se eliminan de nuestros sistemas de respaldo durante un tiempo determinado.</p>
                <p><b>6. SEGURIDAD.</b> Implementamos y actualizamos continuamente medidas de seguridad, administrativas, técnicas y físicas, para proteger su información contra cualquier acceso no autorizado, pérdida, destrucción o alteración.</p>
                <p><b>7. MODIFICACIONES A ESTA POLÍTICA DE PRIVACIDAD.</b> Nos reservamos el derecho de modificar en cualquier momento la presente Política de Privacidad, de conformidad con la legislación aplicable. Si lo hacemos, publicaremos la Política de Privacidad modificada y actualizaremos la fecha de “Última actualización” indicada en la parte superior. En caso de cambios sustanciales, también le comunicaremos las modificaciones por correo electrónico al menos treinta (30) días antes de la fecha de entrada en vigor. Si no está de acuerdo con la Política de Privacidad modificada, puede cancelar su cuenta. Si no cancela su cuenta antes de la fecha de entrada en vigor de la Política de Privacidad modificada, su uso o acceso continuo a la Plataforma Bookyourprogram.com estará sujeto a ella.</p>
                <p><b>8. INFORMACIÓN DE CONTACTO Y ENTIDADES RESPONSABLES DE BOOKYOURPROGRAM.COM.</b>Si tiene preguntas o quejas sobre esta Política de Privacidad o sobre el tratamiento de la información personal por parte de Bookyourprogram.com: (i) si reside en Estados Unidos, póngase en contacto con Bookyourprogram.com a la dirección av. vallarta 3075-7, Guadalajara Jalisco. CP 44110, 
                al correo electrónico de <a href={`mailto:const email : nash.uriza@bookyourprogram.com;`}>{<span style={{color: 'blue'}}>nash.uriza@bookyourprogram.com</span>}</a></p>
                <p><b>9. DEFINICIONES.</b> Los términos que no se encuentran definidos en esta Política de Privacidad tienen la misma definición que en nuestros Términos de Servicio (“Términos”).</p>

            </Collapse.Panel>

            <h2 style={titles}>Términos adicionales</h2>
            {/* Terminos Adicionales */}

            <Collapse.Panel header="Políticas de Cookies">
                <small>Última actualización: 04 de Enero 2025 </small>
                <p>Bookyourprogram.com  utiliza cookies, identificadores móviles, URL de seguimiento, datos de registro y tecnologías similares para proporcionar, proteger y mejorar la Plataforma Bookyourprogram.com. Esta Política de cookies (la “Política”) complementa la Política de privacidad de Bookyourprogram.com y explica cómo y por qué usamos estas tecnologías y las opciones que usted tiene.</p>
                <p><b>Por qué Bookyourprogram.com utiliza estas tecnologías</b></p>
                <p>
                    Utilizamos estas tecnologías con varios fines, tales como:
                    <ul>
                        <li>Permitirle utilizar y acceder a la Plataforma Bookyourprogram.com y a los Servicios de Pago.</li>
                        <li>Permitir, facilitar y agilizar el funcionamiento y el acceso a la Plataforma Bookyourprogram.com</li>
                        <li>Entender mejor cómo navega e interactúa con la Plataforma Bookyourprogram.com y mejorar la plataforma.</li>
                        <li>Ofrecerle publicidad personalizada (por ejemplo, en la Plataforma Bookyourprogram.com, en los correos electrónicos y en sitios web de terceros).</li>
                        <li>Mostrarle el contenido (por ejemplo, anuncios) que sea más relevante para usted.</li>
                        <li>Supervisar y analizar el rendimiento, el funcionamiento y la eficacia de los anuncios y la Plataforma Bookyourprogram.com.</li>
                        <li>Hacer cumplir los convenios legales que rigen el uso de la Plataforma Bookyourprogram.com.</li>
                        <li>Detectar y prevenir fraudes, para fines de confianza y seguridad, e investigaciones</li>
                        <li>Para fines en relación con nuestros propios servicios de asistencia al cliente, análisis, investigación, desarrollo de productos y cumplimiento normativo.</li>
                    </ul>
                </p>
                <p><b>Cookies</b></p>
                <p>Cuando visite la Plataforma Bookyourprogram.com, es posible que coloquemos cookies en su dispositivo. Las cookies son pequeños archivos de texto que los sitios web envían a su computadora u otro dispositivo conectado a Internet para identificar de forma única su navegador o para almacenar información o configuraciones en su navegador. Las cookies nos permiten reconocerlo cuando usted regrese. También nos ayudan a ofrecer una experiencia personalizada y nos podrían permitir detectar ciertos tipos de fraude. En muchos casos, la información que recopilamos utilizando cookies y otras herramientas solo se utiliza de forma no identificable sin referencia a información personal. Por ejemplo, podemos utilizar la información que recopilamos para comprender mejor los patrones de tráfico del sitio web y optimizar la experiencia en nuestro sitio web. En algunos casos, asociamos la información que recopilamos mediante el uso de cookies y otras tecnologías con su información personal.</p>
                <p>Hay dos tipos de cookies que se utilizan en la Plataforma Bookyourprogram.com: (1) “cookies de sesión” y (2) “cookies persistentes”. Las cookies de sesión normalmente vencen cuando cierra su navegador, mientras que las cookies persistentes permanecen en su dispositivo después de cerrar el navegador y pueden utilizarse de nuevo la siguiente vez que acceda a la Plataforma Bookyourprogram.com.</p>
                <p>En muchos casos, usted puede ajustar la configuración de su navegador para administrar las preferencias de cookies y no permitir que se utilicen cookies y otras tecnologías de recopilación de datos. Cada navegador es diferente, así que visite la sección “Ayuda” de su navegador para saber más sobre las preferencias de cookies y otras configuraciones de privacidad disponibles. Por favor tenga en cuenta que si usted decide eliminar o rechazar cookies o borrar el almacenamiento local, esto podría afectar las funciones, la disponibilidad y la funcionalidad de la Plataforma Bookyourprogram.com.</p>
                <p><b>Cookies Flash</b></p>
                <p>Nosotros podemos utilizar cookies Flash, también conocidas como objetos almacenados locales, y tecnologías similares para personalizar y mejorar su experiencia en línea. Una cookie Flash es un pequeño archivo de datos colocado en una computadora utilizando la tecnología Adobe Flash. Adobe Flash Player es una aplicación que permite desarrollar rápidamente contenido dinámico, como clips de vídeo y animaciones.</p>
                <p>Utilizamos cookies Flash para personalizar y mejorar su experiencia en línea y para generar contenido para reproductores Flash. 
                    También podemos usar cookies Flash por motivos de seguridad, para recopilar ciertas métricas del sitio web y para ayudar a recordar la configuración y 
                    las preferencias. Las cookies Flash se administran a través de una interfaz diferente a la que proporciona su navegador. Para administrar las cookies Flash, por favor visite el sitio web de 
                    <a href={`https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html`}>{<span style={{color: 'blue'}}>Adobe.</span>}</a></p>
                <p>Si desactiva las cookies Flash u otras tecnologías similares, tenga en cuenta que es posible que no tenga acceso a ciertos contenidos y características del producto, como el hecho de que su dispositivo recuerde un anuncio que haya visto durante una visita anterior.</p>
                <p><b>Etiquetas de píxel, balizas de web y rastreadores</b></p>
                <p>Las etiquetas de píxel, balizas web y URL de rastreo son imágenes gráficas minúsculas y/o pequeños bloques de código colocados en páginas web, anuncios o en nuestros correos electrónicos que nos permiten determinar si se realizó una acción específica. Cuando usted accede a estas páginas o abre un correo electrónico, nos informan que ha accedido a la página o que ha abierto el correo electrónico. Estas herramientas nos ayudan a medir la respuesta a nuestras comunicaciones y mejorar nuestras páginas web y promociones.</p>
                <p><b>Registros de servidores y otras tecnologías</b></p>
                <p>Nosotros recopilamos muchos tipos diferentes de información de registros de servidores y otras tecnologías. Por ejemplo, recopilamos información sobre el dispositivo que usted utiliza para acceder a la Plataforma Bookyourprogram.com, el tipo de sistema operativo, el tipo de navegador, el dominio y otras configuraciones del sistema, así como el idioma que utiliza su sistema y el país y la zona horaria donde se encuentra su dispositivo. Nuestros registros de servidor también registran la dirección IP del dispositivo que utiliza para conectarse a Internet. Una dirección IP es un identificador único que los dispositivos requieren para identificarse y comunicarse entre sí a través de Internet. También podemos recopilar información sobre el sitio web que visitó antes de llegar a la Plataforma Bookyourprogram.com y el sitio web que visite después de salir de la Plataforma Bookyourprogram.com.</p>
                <p>También podemos recopilar información sobre su uso de la Plataforma Bookyourprogram.com como, por ejemplo, cuando proporcionamos herramientas de accesibilidad. Las herramientas descritas nos ayudan a mejorar la experiencia del usuario y a entregar nuestros servicios.</p>
                <p><b>Información del dispositivo</b></p>
                <p>Nosotros podemos utilizar información relacionada con el dispositivo para autentificar a los usuarios. Por ejemplo, podemos usar su dirección IP, información del navegador u otros datos proporcionados por su navegador o dispositivo para identificar el dispositivo que se utiliza para acceder a nuestra plataforma. También podemos utilizar estas técnicas asociadas a dispositivos para vincularlo con los distintos dispositivos que usted pueda utilizar para acceder a nuestro contenido, incluso con fines de protección contra el fraude y para orientar mejor la publicidad.</p>
                <p><b>Terceros</b></p>
                <p>Bookyourprogram.com permite a terceros recopilar la información descrita más arriba a través de nuestro Servicio y divulga dicha información a terceros con fines comerciales como se describe en esta Política de Privacidad, incluyendo, pero no limitándose a, proporcionar publicidad en nuestro Servicio y en otros lugares basada en las actividades en línea de los usuarios a lo largo del tiempo y en diferentes sitios, servicios, dispositivos.</p>
                <p>Los terceros, incluido Facebook, colocan tecnologías como pixeles y SDK en la Plataforma Bookyourprogram.com. Estas tecnologías (1) nos ayudan a analizar cómo usted utiliza la Plataforma Bookyourprogram.com, por ejemplo, señalando los servicios de terceros a través de los cuales usted llegó; (2) comercializar y publicitar servicios Bookyourprogram.com a usted en la Plataforma Bookyourprogram.com  y en sitios web de terceros; (3) nos ayudan a detectar o prevenir fraudes o realizar evaluaciones de riesgo; y (4) recopilar información sobre sus actividades en la Plataforma Bookyourprogram.com, otros sitios y/o los anuncios en los que usted haya hecho clic. Por ejemplo, para ayudarnos a entender mejor cómo utilizan las personas la Plataforma Bookyourprogram.com, trabajamos con varios socios de análisis, incluyendo Google Analytics. Para evitar que Google Analytics utilice su información para conducir análisis, haga clic aquí para instalar el complemento de inhabilitación para navegadores de Google Analytics. En algunos casos, las cookies se instalan si se cumplen ciertos criterios, como iniciar sesión en el servicio de terceros en el mismo navegador.</p>
                <p>Los terceros, incluido Facebook, también podrían utilizar dichas tecnologías de rastreo para recopilar o 
                    recibir información de la Plataforma Bookyourprogram.com y otros lugares, así como utilizar esa información 
                    para publicar anuncios que crean que probablemente sean de su interés, así como medir la eficacia de sus anuncios 
                    tanto en la Plataforma Bookyourprogram.com como en otros sitios web y servicios en línea. Las cookies de segmentación y 
                    de publicidad que utilizamos podrán incluir a Google y otras redes y servicios de publicidad que utilizamos ocasionalmente. 
                    Consulte aquí para obtener información sobre cómo Google administra los datos de sus productos publicitarios. 
                    Para obtener más información sobre las cookies direccionadas y de publicidad y sobre cómo puede optar por darse de baja, 
                    usted puede visitar la página de baja voluntaria de la Network Advertising Initiative, la 
                    <a href={`https://www.networkadvertising.org/choices/`}>{<span style={{color: 'blue'}}> página de baja voluntaria de la Digital Advertising Initiative</span>}</a> o  
                    <a href={`https://youronlinechoices.eu/`}>{<span style={{color: 'blue'}}> http://youronlinechoices.eu.</span>}</a> 
                    Si usted desea darse de baja de Google Analytics con fines de publicidad gráfica o personalizar anuncios de la red de Display de Google, usted puede visitar la página de configuración de 
                    <a href={`https://www.google.com/settings/ads`}>{<span style={{color: 'blue'}}> Google Ads.</span>}</a>  
                    En la medida en que la tecnología publicitaria esté integrada en la Plataforma Bookyourprogram.com y usted opte por no recibir publicidad personalizada, usted podría seguir recibiendo contenido publicitario. 
                    En tal caso, el contenido publicitario no estará adaptado a sus intereses. Asimismo, nosotros no controlamos dichos enlaces de baja voluntaria, ni respondemos por la disponibilidad o la precisión de estos mecanismos. 
                    Los usuarios pueden deshabilitar la recopilación y el uso de información para la orientación de anuncios actualizando la configuración de anuncios de su cuenta de Facebook. Para obtener más información sobre cómo compartimos 
                     {/* Añadir link a Política de Privacidad */}
                    información con los socios, consulte la Política de Privacidad de Bookyourprogram.com</p>
                <p></p>
                <p></p>
                <p></p>
            </Collapse.Panel>

            <Collapse.Panel header="Compartir y divulgar información con otros Miembros">
                <p>
                Para ayudar a facilitar el proceso de selección y aplicación a un programa de estudios en el extranjero, es posible que tengamos que compartir información en determinadas situaciones, como se describe a continuación.
                </p>
                <ul>
                    <li>
                        <b>Entre el equipo de consultoría, servicio al cliente, equipo de finanzas:</b>
                        <ul>
                            <li>Cuando desea hacer una cita a través de la plataforma para conocer más sobre las propuestas para estudiar en el extranjero; es posible que se comparta la información de su perfil para poder conocer más sus intereses y poder hacer una consultoría de calidad. </li>
                            <li>Cuando realizará un pago a través de la plataforma o desea apoyo de servicio al cliente, es posible que se compartan sus datos personales, intereses, programa al cuál se quiere inscribir para un mejor servicio. </li>
                            <li>Cuando usted tiene una inscripción a un programa al extranjero, se comparten documentos para llevar una aceptación a un programa de estudios al extranjero exitoso como por ejemplo; formatos de aplicación, copias de pasaportes, calificaciones y certificados de estudios, contactos de terceras personas como por ejemplo contactos de emergencia, (dichos contactos proporcionados por usted).</li>
                        </ul>
                    </li>
                    <li>
                        <b>Entre instituciones, dptos de admisiones, empresas externas para complementar el proceso de aplicación:</b>
                        <ul>
                            <li>Al momento de aplicar a un programa al extranjero, Bookyourprogram.com compartirá su carpeta con los documentos solicitados para llevar a cabo su proceso de aplicación a la institución deseada. </li>
                            <li>Bookyourprogram.com podría recomendar su aplicación a empresas de servicios externos como son traductores, empresas de financiamiento educativo, empresas de colocación de hospedajes, servicios de recepción de aeropuerto, empresas de seguros de gastos médicos, gestores de visas entre otros.  Al momento que comienza un proceso de aplicación con nosotros,  Usted está de acuerdo  en aceptar que Bookyourprogram.com enviará información en representación de Usted para gestionar su aplicación /inscripción en el programa de estudios al extranjero seleccionado.</li>
                        </ul>
                    </li>
                </ul>
                <p>Bookyourprogram.com no comparte su información con otros estudiantes o clientes, pero pudiera compartir su experiencia de estudios al extranjero  los cuáles bajo su autorización compartió en nuestro portal.</p>
            </Collapse.Panel>
         </Collapse>
        </>
    )
}

export { LegalsBYP }