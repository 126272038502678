import { PublicTheme } from '../../common/theme/PublicTheme'
// import { HomeSectionJ } from '../home/components/homeSectionJ'
// import { HomeSectionK } from '../home/components/homeSectionK'
// import header from './../../common/assets/images/fotos-portada.png'
import { TextTermsAndConditions } from './components/TextTermsAndConditions'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'


//Terminos y conidicones para el alumno que se esta inscribiendo
const URL_ABOUT_TERMS_AND_CONDITIONS_PAGE = '/about/terms_and_conditions'

function AboutTermsAndConditionsPage() {

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <PublicTheme>
        {/* <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '12.5px' }}>
          <img src={header} className='image-about-responsive' alt='' />
        </div> */}
        {/* <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '4vh 0px', fontSize: '15px' }}>
          <a href='/about/terms_and_conditions/institution' style={{ color: 'var(--blue-200)', fontWeight: 'bold' }}>Terms and conditions Institutions</a>
          <a href='/about/terms_and_conditions/agency' style={{ color: 'var(--blue-200)', fontWeight: 'bold' }}>Terms and conditions Agencies</a>
        </div> */}
        <TextTermsAndConditions />
        {/* <HomeSectionJ /> */}
        {/* <HomeSectionK /> */}
        </PublicTheme>
      </PageProtected>
  )
}

export { AboutTermsAndConditionsPage, URL_ABOUT_TERMS_AND_CONDITIONS_PAGE }
